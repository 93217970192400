import React, { Fragment } from 'react'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Navbar8 from '../components/navbar8'
import CTA26 from '../components/cta26'
import ContentList6 from '../components/content-list6'
import Testimonial17 from '../components/testimonial17'
import Footer4 from '../components/footer4'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container1">
      <Helmet>
        <title>HyperRaided</title>
        <meta
          name="description"
          content="HyperRaided is a Custom Made Network by pixity and nic4y."
        />
        <meta property="og:title" content="HyperRaided" />
        <meta
          property="og:description"
          content="HyperRaided is a Custom Made Network by pixity and nic4y."
        />
      </Helmet>
      <Navbar8
        link1={
          <Fragment>
            <span className="home-text10 thq-body-small thq-link">
              ANNOUNCEMENTS
            </span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="home-text11 thq-body-small thq-link">RULES</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="home-text12 thq-body-small thq-link">STORE</span>
          </Fragment>
        }
        page1={
          <Fragment>
            <span className="home-text13 thq-body-large">Undefinied</span>
          </Fragment>
        }
        page2={
          <Fragment>
            <span className="home-text14 thq-body-large">Page Two</span>
          </Fragment>
        }
        page3={
          <Fragment>
            <span className="home-text15 thq-body-large">Page Three</span>
          </Fragment>
        }
        page4={
          <Fragment>
            <span className="home-text16 thq-body-large">Page Four</span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="home-text17">Copy IP</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="home-text18">Join the Discord</span>
          </Fragment>
        }
        logoSrc="/server-icon-1500h.png"
        link1Url="https://discord.com/invite/D2kPc5SHQJ"
        link2Url="https://discord.com/invite/D2kPc5SHQJ"
        link3Url="https://discord.com/invite/D2kPc5SHQJ"
        rootClassName="navbar8root-class-name"
        page1Description={
          <Fragment>
            <span className="home-text19 thq-body-small">
              Page One Description
            </span>
          </Fragment>
        }
        page2Description={
          <Fragment>
            <span className="home-text20 thq-body-small">
              Page Two Description
            </span>
          </Fragment>
        }
        page3Description={
          <Fragment>
            <span className="home-text21 thq-body-small">
              Page Three Description
            </span>
          </Fragment>
        }
        page4Description={
          <Fragment>
            <span className="home-text22 thq-body-small">
              Page Four Description
            </span>
          </Fragment>
        }
      ></Navbar8>
      <img alt="image" src="/ddddddmctitle-200h.png" className="home-image" />
      <div className="home-header78">
        <div className="home-column thq-section-padding thq-section-max-width">
          <div className="home-content">
            <h1 className="home-text23 thq-heading-1">
              <span className="home-text24">Welcome to a New Era.</span>
              <br></br>
            </h1>
            <p className="home-text26 thq-body-large">
              <span>IP: mc.hyperraided.org</span>
              <br></br>
            </p>
          </div>
          <div className="home-actions">
            <button type="button" className="thq-button-filled home-button1">
              <span className="home-text29 thq-body-small">
                <span>mc.hyperraided.org</span>
                <br></br>
              </span>
            </button>
            <a
              href="https://discord.gg/D2kPc5SHQJ"
              target="_blank"
              rel="noreferrer noopener"
              className="home-button2 thq-button-outline"
            >
              <span className="thq-body-small">
                <span>Join the Discord</span>
                <br></br>
              </span>
            </a>
          </div>
        </div>
        <div>
          <div className="home-container3">
            <Script
              html={`<style>
  @keyframes scroll-x {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - 16px));
    }
  }

  @keyframes scroll-y {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(calc(-100% - 16px));
    }
  }
</style>
`}
            ></Script>
          </div>
        </div>
      </div>
      <CTA26
        action1={
          <Fragment>
            <span className="home-text35">Join the Discord!</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text36 thq-body-large">
              Stay updated with the latest news, events, and announcements in
              the Discord!
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text37 thq-heading-2">
              Join our community today!
            </span>
          </Fragment>
        }
      ></CTA26>
      <ContentList6
        content3={
          <Fragment>
            <span className="home-text38 thq-body-small">
              KitPvP is made to be Fun. Level up and unlock new kits that
              include Custom Weapons and Armor to PvP other players. 
            </span>
          </Fragment>
        }
        heading10={
          <Fragment>
            <span className="home-text39 thq-heading-2">Events</span>
          </Fragment>
        }
        heading6={
          <Fragment>
            <span className="home-text40 thq-heading-3">Abilities</span>
          </Fragment>
        }
        heading4={
          <Fragment>
            <span className="home-text41 thq-heading-3">Custom Maps</span>
          </Fragment>
        }
        heading5={
          <Fragment>
            <span className="home-text42 thq-heading-3">
              <span>Leaderboards</span>
              <br></br>
            </span>
          </Fragment>
        }
        heading12={
          <Fragment>
            <span className="home-text45 thq-heading-3">Rewards</span>
          </Fragment>
        }
        content9={
          <Fragment>
            <span className="home-text46 thq-body-small">
              <span>
                PvP, Custom Armor, Custom Pets, Custom Weapons and Tools,
                Bosses, and More!
              </span>
              <br></br>
              <br></br>
              <span>This content was last updated 9/1/2024</span>
              <br></br>
            </span>
          </Fragment>
        }
        content7={
          <Fragment>
            <span className="home-text52 thq-body-small">
              We have many constantly evolving Custom Features for our community
              to enjoy. There are plenty more to explore In-Game!
            </span>
          </Fragment>
        }
        heading11={
          <Fragment>
            <span className="home-text53 thq-heading-3">Custom Minigames</span>
          </Fragment>
        }
        button2={
          <Fragment>
            <span className="home-text54">Daily Events</span>
          </Fragment>
        }
        button1={
          <Fragment>
            <span className="home-text55">KitPvP</span>
          </Fragment>
        }
        heading9={
          <Fragment>
            <span className="home-text56 thq-heading-3">
              <span>Other Custom Content</span>
              <br></br>
            </span>
          </Fragment>
        }
        content6={
          <Fragment>
            <span className="home-text59 thq-body-small">
              PvP with Custom Abilities in the Main Arena, Players can choose
              whether or not they want to Duel, or FFA.
            </span>
          </Fragment>
        }
        heading8={
          <Fragment>
            <span className="home-text60 thq-heading-3">Custom Quest Npcs</span>
          </Fragment>
        }
        content13={
          <Fragment>
            <span className="home-text61 thq-body-small">
              These Events are Hosted Daily! If you want to help out, Apply for
              Event Host to Host Events!
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text62 thq-heading-2">KitPvP Features</span>
          </Fragment>
        }
        button={
          <Fragment>
            <span className="home-text63">Custom Survival</span>
          </Fragment>
        }
        heading2={
          <Fragment>
            <span className="home-text64 thq-heading-3">Custom Leveling</span>
          </Fragment>
        }
        content4={
          <Fragment>
            <span className="home-text65 thq-body-small">
              Our maps are all custom made, Most of the map&apos;s credit goes
              to nic4y.
            </span>
          </Fragment>
        }
        heading7={
          <Fragment>
            <span className="home-text66 thq-heading-2">Main Features</span>
          </Fragment>
        }
        content12={
          <Fragment>
            <span className="home-text67 thq-body-small">
              Obtain rewards for all servers when ranking high on the
              leaderboards! Compete for keys and cosmetic rewards.
            </span>
          </Fragment>
        }
        content2={
          <Fragment>
            <span className="home-text68 thq-body-small">
              Every kill you get will reward you with XP, needed to Level up!
            </span>
          </Fragment>
        }
        content10={
          <Fragment>
            <span className="home-text69 thq-body-small">
              Daily Events are a great way to earn global rewards for any of our
              Gamemodes
            </span>
          </Fragment>
        }
        heading3={
          <Fragment>
            <span className="home-text70 thq-heading-3">Custom Kits</span>
          </Fragment>
        }
        content5={
          <Fragment>
            <span className="home-text71 thq-body-small">
              Grind to put yourself on the leaderboards and earn legendary
              rewards.
            </span>
          </Fragment>
        }
        heading13={
          <Fragment>
            <span className="home-text72 thq-heading-3">Daily!</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text73 thq-body-small">
              KitPvP Crafted to perfection. Enjoy our Custom Content to explore.
            </span>
          </Fragment>
        }
        content11={
          <Fragment>
            <span className="home-text74 thq-body-small">
              <span>
                We have the common gamemodes like Spleef, Anvil Drop, Color
                Drop, Infected, and Parkour. We also have custom Party Games.
              </span>
              <br></br>
            </span>
          </Fragment>
        }
      ></ContentList6>
      <Testimonial17
        review1={
          <Fragment>
            <span className="home-text77 thq-body-small">
              <span className="home-text78">e</span>
              <br></br>
            </span>
          </Fragment>
        }
        review2={
          <Fragment>
            <span className="home-text80 thq-body-small">
              hyperraided is amazing
            </span>
          </Fragment>
        }
        review3={
          <Fragment>
            <span className="home-text81 thq-body-small">
              Self Glaze Alert: This server is amazing. (I made it)
            </span>
          </Fragment>
        }
        review4={
          <Fragment>
            <span className="home-text82 thq-body-small">Mogger</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text83 thq-body-small">
              the staff team is not being held hostage
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text84 thq-heading-2">Testimonials</span>
          </Fragment>
        }
        author1Src="/nic4y-200h.png"
        author2Src="/horizon-200h.png"
        author3Src="/pixity%20profile%20icon-200h.png"
        author4Src="/mogger-200w.png"
        author1Name={
          <Fragment>
            <span className="home-text85 thq-body-large">nic4y</span>
          </Fragment>
        }
        author2Name={
          <Fragment>
            <span className="home-text86 thq-body-large">Horizon</span>
          </Fragment>
        }
        author3Name={
          <Fragment>
            <span className="home-text87 thq-body-large">pixity</span>
          </Fragment>
        }
        author4Name={
          <Fragment>
            <span className="home-text88 thq-body-large">Logan_Mario</span>
          </Fragment>
        }
        author1Position={
          <Fragment>
            <span className="home-text89 thq-body-small">
              Admin, Designer, Builder, HyperRaided.org
            </span>
          </Fragment>
        }
        author2Position={
          <Fragment>
            <span className="home-text90 thq-body-small">
              <span>JrAdmin, HyperRaided.org</span>
              <br></br>
            </span>
          </Fragment>
        }
        author3Position={
          <Fragment>
            <span className="home-text93 thq-body-small">
              OWNER LOL!!!, HyperRaided.org
            </span>
          </Fragment>
        }
        author4Position={
          <Fragment>
            <span className="home-text94 thq-body-small">
              <span>Goat, G, HyperRaided.org</span>
              <br></br>
            </span>
          </Fragment>
        }
      ></Testimonial17>
      <Footer4
        link1={
          <Fragment>
            <span className="home-text97 thq-body-small">HOME</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="home-text98 thq-body-small">ANNOUNCEMENTS</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="home-text99 thq-body-small">RULES</span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="home-text100 thq-body-small">STORE</span>
          </Fragment>
        }
        link5={
          <Fragment>
            <span className="home-text101 thq-body-small">
              <span>DISCORD</span>
              <br></br>
            </span>
          </Fragment>
        }
        logoSrc="/ddddddmctitle-1500h.png"
        termsLink={
          <Fragment>
            <span className="home-text104 thq-body-small">
              Terms and Conditions
            </span>
          </Fragment>
        }
        cookiesLink={
          <Fragment>
            <span className="home-text105 thq-body-small">Cookies Policy</span>
          </Fragment>
        }
        privacyLink={
          <Fragment>
            <span className="home-text106 thq-body-small">Privacy Policy</span>
          </Fragment>
        }
      ></Footer4>
    </div>
  )
}

export default Home
