import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './content-list6.css'

const ContentList6 = (props) => {
  const [isTermsVisible, setIsTermsVisible] = useState(true)
  const [isPrivacyVisible, setIsPrivacyVisible] = useState(false)
  const [isRefundVisible, setIsRefundVisible] = useState(false)
  return (
    <div className="content-list6-container1 thq-section-padding">
      <div className="content-list6-max-width thq-section-max-width">
        <div className="content-list6-container2 thq-flex-column">
          {isTermsVisible && (
            <button
              onClick={() => {
                setIsRefundVisible(false)
                setIsPrivacyVisible(false)
                setIsTermsVisible(true)
              }}
              className="thq-button-filled"
            >
              <span>
                {props.button ?? (
                  <Fragment>
                    <span className="content-list6-text32">
                      Custom Survival
                    </span>
                  </Fragment>
                )}
              </span>
            </button>
          )}
          {!isTermsVisible && (
            <button
              onClick={() => {
                setIsRefundVisible(false)
                setIsTermsVisible(true)
                setIsPrivacyVisible(false)
              }}
              className="thq-button-outline"
            >
              <span>
                {props.button ?? (
                  <Fragment>
                    <span className="content-list6-text32">
                      Custom Survival
                    </span>
                  </Fragment>
                )}
              </span>
            </button>
          )}
          {!isPrivacyVisible && (
            <button
              onClick={() => {
                setIsTermsVisible(false)
                setIsRefundVisible(false)
                setIsPrivacyVisible(true)
              }}
              className="thq-button-outline"
            >
              <span>
                {props.button1 ?? (
                  <Fragment>
                    <span className="content-list6-text26">
                      Privacy statement
                    </span>
                  </Fragment>
                )}
              </span>
            </button>
          )}
          {isPrivacyVisible && (
            <button
              onClick={() => {
                setIsTermsVisible(false)
                setIsPrivacyVisible(true)
                setIsRefundVisible(false)
              }}
              className="thq-button-filled"
            >
              <span>
                {props.button1 ?? (
                  <Fragment>
                    <span className="content-list6-text26">
                      Privacy statement
                    </span>
                  </Fragment>
                )}
              </span>
            </button>
          )}
          {!isRefundVisible && (
            <button
              onClick={() => {
                setIsTermsVisible(false)
                setIsPrivacyVisible(false)
                setIsRefundVisible(true)
              }}
              className="thq-button-outline"
            >
              <span>
                {props.button2 ?? (
                  <Fragment>
                    <span className="content-list6-text25">Refund Policy</span>
                  </Fragment>
                )}
              </span>
            </button>
          )}
          {isRefundVisible && (
            <button
              onClick={() => {
                setIsRefundVisible(true)
                setIsTermsVisible(false)
                setIsPrivacyVisible(false)
              }}
              className="thq-button-filled"
            >
              <span>
                {props.button2 ?? (
                  <Fragment>
                    <span className="content-list6-text25">Refund Policy</span>
                  </Fragment>
                )}
              </span>
            </button>
          )}
        </div>
        <div className="content-list6-container3 thq-flex-column">
          {isTermsVisible && (
            <div className="content-list6-container4">
              <ul className="content-list6-ul1 thq-flex-column">
                <li className="content-list6-li10 list-item thq-flex-column">
                  <h2>
                    {props.heading7 ?? (
                      <Fragment>
                        <h2 className="content-list6-text35 thq-heading-2">
                          Main Features
                        </h2>
                      </Fragment>
                    )}
                  </h2>
                  <p>
                    {props.content7 ?? (
                      <Fragment>
                        <p className="content-list6-text23 thq-body-small">
                          Donec vitae justo vitae urna vehicula accumsan. Nam
                          auctor, tortor eget consequat ultricies, libero lectus
                          aliquet odio.
                        </p>
                      </Fragment>
                    )}
                  </p>
                  <ul className="content-list6-ul2 thq-flex-column">
                    <li className="list-item">
                      <h3>
                        {props.heading8 ?? (
                          <Fragment>
                            <h3 className="content-list6-text29 thq-heading-3">
                              Privacy Policy
                            </h3>
                          </Fragment>
                        )}
                      </h3>
                      <p>
                        {props.content2 ?? (
                          <Fragment>
                            <p className="content-list6-text37 thq-body-small">
                              Nulla facilisi. Sed nec purus eu tellus fringilla
                              ultricies. Vestibulum ante ipsum primis in
                              faucibus orci luctus et ultrices posuere cubilia
                              Curae.
                            </p>
                          </Fragment>
                        )}
                      </p>
                    </li>
                    <li className="list-item">
                      <h3>
                        {props.heading9 ?? (
                          <Fragment>
                            <h3 className="content-list6-text27 thq-heading-3">
                              Terms and Conditions
                            </h3>
                          </Fragment>
                        )}
                      </h3>
                      <p>
                        {props.content9 ?? (
                          <Fragment>
                            <p className="content-list6-text22 thq-body-small">
                              Quisque vitae felis eget mi fermentum varius.
                              Nullam non ligula sed purus tincidunt tristique.
                            </p>
                          </Fragment>
                        )}
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          )}
          {isPrivacyVisible && (
            <div className="content-list6-container5">
              <ul className="thq-flex-column">
                <li className="content-list6-li13 list-item thq-flex-column">
                  <h1>
                    {props.heading1 ?? (
                      <Fragment>
                        <h1 className="content-list6-text31 thq-heading-2">
                          Server Rules
                        </h1>
                      </Fragment>
                    )}
                  </h1>
                  <span>
                    {props.content1 ?? (
                      <Fragment>
                        <span className="content-list6-text42 thq-body-small">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Proin eget mauris vel nunc varius ultricies.
                        </span>
                      </Fragment>
                    )}
                  </span>
                  <ul className="content-list6-ul4 thq-flex-column">
                    <li className="list-item">
                      <h1>
                        {props.heading2 ?? (
                          <Fragment>
                            <h1 className="content-list6-text33 thq-heading-3">
                              Announcements
                            </h1>
                          </Fragment>
                        )}
                      </h1>
                      <span>
                        {props.content2 ?? (
                          <Fragment>
                            <p className="content-list6-text37 thq-body-small">
                              Nulla facilisi. Sed nec purus eu tellus fringilla
                              ultricies. Vestibulum ante ipsum primis in
                              faucibus orci luctus et ultrices posuere cubilia
                              Curae.
                            </p>
                          </Fragment>
                        )}
                      </span>
                    </li>
                    <li className="list-item">
                      <h1>
                        {props.heading3 ?? (
                          <Fragment>
                            <h1 className="content-list6-text39 thq-heading-3">
                              Testimonials
                            </h1>
                          </Fragment>
                        )}
                      </h1>
                      <span>
                        {props.content3 ?? (
                          <Fragment>
                            <span className="content-list6-text16 thq-body-small">
                              Vivamus non semper metus. Integer varius felis a
                              est aliquam, at ultricies metus tincidunt.
                            </span>
                          </Fragment>
                        )}
                      </span>
                    </li>
                    <li className="list-item">
                      <h1>
                        {props.heading4 ?? (
                          <Fragment>
                            <h1 className="content-list6-text19 thq-heading-3">
                              Locations
                            </h1>
                          </Fragment>
                        )}
                      </h1>
                      <span>
                        {props.content4 ?? (
                          <Fragment>
                            <span className="content-list6-text34 thq-body-small">
                              Pellentesque habitant morbi tristique senectus et
                              netus et malesuada fames ac turpis egestas.
                            </span>
                          </Fragment>
                        )}
                      </span>
                    </li>
                    <li className="list-item">
                      <h1>
                        {props.heading5 ?? (
                          <Fragment>
                            <h1 className="content-list6-text20 thq-heading-3">
                              Blog Posts
                            </h1>
                          </Fragment>
                        )}
                      </h1>
                      <span>
                        {props.content5 ?? (
                          <Fragment>
                            <span className="content-list6-text40 thq-body-small">
                              Fusce auctor, nunc sit amet tempor fermentum,
                              lorem sapien tincidunt justo, nec bibendum turpis
                              libero at nisi.
                            </span>
                          </Fragment>
                        )}
                      </span>
                    </li>
                    <li className="list-item">
                      <h1>
                        {props.heading6 ?? (
                          <Fragment>
                            <h1 className="content-list6-text18 thq-heading-3">
                              Galleries
                            </h1>
                          </Fragment>
                        )}
                      </h1>
                      <span>
                        {props.content6 ?? (
                          <Fragment>
                            <span className="content-list6-text28 thq-body-small">
                              Curabitur id justo quis lacus tincidunt vehicula.
                              Sed nec mauris ut nisl fermentum tincidunt.
                            </span>
                          </Fragment>
                        )}
                      </span>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          )}
          {isRefundVisible && (
            <div className="content-list6-container6">
              <ul className="content-list6-ul5 thq-flex-column">
                <li className="content-list6-li19 list-item thq-flex-column">
                  <h1>
                    {props.heading10 ?? (
                      <Fragment>
                        <h1 className="content-list6-text17 thq-heading-2">
                          About Us
                        </h1>
                      </Fragment>
                    )}
                  </h1>
                  <span>
                    {props.content10 ?? (
                      <Fragment>
                        <span className="content-list6-text38 thq-body-small">
                          Aenean vel velit ac nisi lobortis eleifend. Sed vitae
                          urna nec velit congue vehicula.
                        </span>
                      </Fragment>
                    )}
                  </span>
                  <ul className="content-list6-ul6 thq-flex-column">
                    <li className="list-item">
                      <h1>
                        {props.heading11 ?? (
                          <Fragment>
                            <h1 className="content-list6-text24 thq-heading-3">
                              FAQs
                            </h1>
                          </Fragment>
                        )}
                      </h1>
                      <span>
                        {props.content11 ?? (
                          <Fragment>
                            <span className="content-list6-text43 thq-body-small">
                              Suspendisse potenti. In hac habitasse platea
                              dictumst. Ut eget magna in mauris vestibulum
                              luctus.
                            </span>
                          </Fragment>
                        )}
                      </span>
                    </li>
                    <li className="list-item">
                      <h1>
                        {props.heading12 ?? (
                          <Fragment>
                            <h1 className="content-list6-text21 thq-heading-3">
                              Community Guidelines
                            </h1>
                          </Fragment>
                        )}
                      </h1>
                      <span>
                        {props.content12 ?? (
                          <Fragment>
                            <span className="content-list6-text36 thq-body-small">
                              Phasellus auctor lacus in mi efficitur, sed
                              rhoncus libero ultricies. Sed nec dolor at odio
                              fermentum tincidunt.
                            </span>
                          </Fragment>
                        )}
                      </span>
                    </li>
                    <li className="list-item">
                      <h1>
                        {props.heading13 ?? (
                          <Fragment>
                            <h1 className="content-list6-text41 thq-heading-3">
                              Events
                            </h1>
                          </Fragment>
                        )}
                      </h1>
                      <span>
                        {props.content13 ?? (
                          <Fragment>
                            <span className="content-list6-text30 thq-body-small">
                              Duis non libero nec mauris malesuada imperdiet.
                              Vestibulum ante ipsum primis in faucibus orci
                              luctus et ultrices posuere cubilia Curae.
                            </span>
                          </Fragment>
                        )}
                      </span>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

ContentList6.defaultProps = {
  content3: undefined,
  heading10: undefined,
  heading6: undefined,
  heading4: undefined,
  heading5: undefined,
  heading12: undefined,
  content9: undefined,
  content7: undefined,
  heading11: undefined,
  button2: undefined,
  button1: undefined,
  heading9: undefined,
  content6: undefined,
  heading8: undefined,
  content13: undefined,
  heading1: undefined,
  button: undefined,
  heading2: undefined,
  content4: undefined,
  heading7: undefined,
  content12: undefined,
  content2: undefined,
  content10: undefined,
  heading3: undefined,
  content5: undefined,
  heading13: undefined,
  content1: undefined,
  content11: undefined,
}

ContentList6.propTypes = {
  content3: PropTypes.element,
  heading10: PropTypes.element,
  heading6: PropTypes.element,
  heading4: PropTypes.element,
  heading5: PropTypes.element,
  heading12: PropTypes.element,
  content9: PropTypes.element,
  content7: PropTypes.element,
  heading11: PropTypes.element,
  button2: PropTypes.element,
  button1: PropTypes.element,
  heading9: PropTypes.element,
  content6: PropTypes.element,
  heading8: PropTypes.element,
  content13: PropTypes.element,
  heading1: PropTypes.element,
  button: PropTypes.element,
  heading2: PropTypes.element,
  content4: PropTypes.element,
  heading7: PropTypes.element,
  content12: PropTypes.element,
  content2: PropTypes.element,
  content10: PropTypes.element,
  heading3: PropTypes.element,
  content5: PropTypes.element,
  heading13: PropTypes.element,
  content1: PropTypes.element,
  content11: PropTypes.element,
}

export default ContentList6
